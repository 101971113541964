import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vim-campaign-image-dialog',
  templateUrl: './campaign-image-dialog.component.html',
  styleUrls: ['./campaign-image-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignImageDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CampaignImageDialogData,
    public dialogRef: MatDialogRef<CampaignImageDialogComponent>
  ) {}
}

export interface CampaignImageDialogData {
  title: string;
  path: string;
}
