import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { VimTeamsInvitation } from '../invitations.data';
import { SnackBarColor, ToastService } from '../../../../../shared/shared-ui/services/toast/toast.service';

@Component({
  selector: 'vim-invitation-overview',
  templateUrl: './invitation-overview.component.html',
  styleUrls: ['./invitation-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class InvitationOverviewComponent {
  @Input()
  public invitation!: VimTeamsInvitation;

  public snackBarColor = SnackBarColor;
  public acceptMessage = 'You joined to The Design Team Berlin';
  public rejectMessage = 'You rejected the invitation to the Design Team Berlin';
  constructor(public toastService: ToastService) {}
}
