import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[vimAutoFocus]'
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    (this.host.nativeElement as HTMLElement).focus();
  }
}
