import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { AdminPanelStore } from '../../../../store/admin-panel/admin-panel.store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[vimShowIfAdminEditor]',
  hostDirectives: [NgIf]
})
export class ShowIfAdminEditorDirective implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private adminPanelStore: AdminPanelStore,
    private nfIfDirective: NgIf
  ) {}

  ngOnInit() {
    this.adminPanelStore.canEditAdminContent$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => (this.nfIfDirective.ngIf = true));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
