import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { CampaignUnit } from '../../../../client/campaigns/campaignsData';

@Component({
  selector: 'vim-campaign-unit',
  templateUrl: './campaign-unit.component.html',
  styleUrls: ['./campaign-unit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignUnitComponent {
  @Input() unit: CampaignUnit;
  @Input() points: number;
  @Input() hidePicture = false;
  @Input() @HostBinding('class') cssClass: string;

  constructor() {}
}
