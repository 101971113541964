import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/state';
import { AdminPanelStore } from '../../../../store/admin-panel/admin-panel.store';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[vimHideIfNotAdminEditor]'
})
export class HideIfNotAdminEditorDirective implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private store$: Store<AppState>,
    private adminPanelStore: AdminPanelStore,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    this.adminPanelStore.canEditAdminContent$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((canEdit) => canEdit === false)
      )
      .subscribe(() => (this.elRef.nativeElement as HTMLElement).setAttribute('style', 'display:none'));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
