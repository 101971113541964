<div fxHide.gt-sm="true" class="mobile-invitation-details" fxLayout="row" fxLayoutAlign="space-between">
  <span class="grey-text">Date: {{ invitation.invitationDate }} </span>
  <span class="grey-text">by: {{ invitation.inviter }}</span>
</div>
<!-- <vim-team-overview [team]="invitation.team"></vim-team-overview> -->
<div fxShow.lt-md="true" fxLayout="row" fxLayoutAlign.gt-sm="stretch" fxLayoutAlign.lt-md="center">
  <div
    fxFlex.gt-sm="50"
    fxLayout.gt-sm="row"
    ngClass.gt-sm="desktop-invitation-buttons"
    fxLayout.lt-md="column"
    fxFlex.lt-md="90"
    fxFlexAlign.lt-md="center"
    fxLayoutGap="16px"
  >
    <button mat-button color="primary" vim-button id='joinTeam'>Join the team</button>
    <button mat-button color="secondary" class="reject-button" vim-button id='rejectInvitation'>Reject the invitation
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="center" fxFlex="14.5" fxHide.lt-md="true">
    <span class="grey-text">Date: {{ invitation.invitationDate }} </span>
  </div>
  <div fxLayout="column" fxLayoutAlign="center" fxFlex="38" fxHide.lt-md="true">
    <span class="grey-text">by: {{ invitation.inviter }}</span>
  </div>
</div>
