<div class="header" (click)="toggle()" fxLayout [class.second-level]="secondLevel">
  <span [class]="titleClass">{{title | translate}}</span>
  <mat-icon *ngIf="expanded | async else collapsed">keyboard_arrow_down</mat-icon>
  <ng-template #collapsed>
    <mat-icon>keyboard_arrow_up</mat-icon>
  </ng-template>
</div>
<div class="content" [hidden]="(expanded | async) === false">
  <ng-content></ng-content>
</div>
