// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  reduxDevTool: true,
  oauthClientId: '515f492c-db54-420a-9720-3e72a9804e8e',
  scope: 'https://VimoveIntegration.onmicrosoft.com/api/read',
  oauthAuthorityUrl: 'https://vimoveintegration.b2clogin.com/VimoveIntegration.onmicrosoft.com/B2C_1_SignInSignUp/v2.0',
  authWellknownEndpointUrl:
    'https://vimoveintegration.b2clogin.com/VimoveIntegration.onmicrosoft.com/B2C_1_SignInSignUp/v2.0/.well-known/openid-configuration',
  registerUrl: 'https://account-integration.viessmann.com/register-end-customer?redirect=',
  baseUrl: 'https://api-integration.viessmann.com/vimove/v1',
  appId: '24b7141e-5ffd-44c6-ac5b-b475f2bd9ca6'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
