import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'vim-expandable',
  templateUrl: './expandable.component.html',
  styleUrls: ['./expandable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableComponent {
  @Input() title: string;
  @Input() titleClass: string;
  @Input() secondLevel: boolean;

  private _expanded = false;
  expanded = new BehaviorSubject<boolean>(false);

  constructor() {}

  toggle(): void {
    this._expanded = !this._expanded;
    this.expanded.next(this._expanded);
  }
}
