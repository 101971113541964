import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../../client/http.service';

@Pipe({
  name: 'secure'
})
export class SecureImagePipe implements PipeTransform {
  constructor(
    private http: HttpService,
    private sanitizer: DomSanitizer
  ) {}

  transform(url: string): Observable<SafeUrl> {
    if (url) {
      return this.http
        .getBlob(url)
        .pipe(map((val: Blob) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
    } else {
      return EMPTY;
    }
  }
}
