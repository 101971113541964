import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Displays only meters for values below 1000, kilometers and meters otherwise
// except case when value%1000 equals zero when only kilometers are displayed
@Pipe({
  name: 'metersAndOrKilometers'
})
export class MetersAndOrKilometersPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(meters: number): string {
    const mUnit = this.translateService.instant('unit_meter') as string,
      kmUnit = this.translateService.instant('unit_km') as string;
    if (isNaN(meters)) {
      return `0 ${mUnit}`;
    }
    if (meters < 1000) {
      return `${Math.floor(meters)} ${mUnit}`;
    }
    if (meters >= 1000 && meters % 1000 === 0) {
      return `${meters / 1000} ${kmUnit}`;
    }
    return `${Math.floor(meters / 1000)} ${kmUnit} ${Math.floor(meters) % 1000} ${mUnit}`;
  }
}
